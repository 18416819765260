import React from 'react';

const friends = [
  // Ajouter ici la liste des amis avec leurs avatars
  { name: 'Max Reymond', avatar: 'url1' },
  { name: 'Lea Pascal', avatar: 'url2' },
  // etc.
];

function FriendsList() {
  return (
    <div className="overflow-x-auto flex space-x-4 p-4 bg-black">
      {friends.map((friend, index) => (
        <div key={index} className="flex flex-col items-center text-white">
          <img src={friend.avatar} alt={friend.name} className="rounded-full w-16 h-16" />
          <span className="text-xs">{friend.name}</span>
        </div>
      ))}
    </div>
  );
}

export default FriendsList;
