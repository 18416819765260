// src/components/SearchBar.js
import React from 'react';
import { FaArrowLeft } from 'react-icons/fa';

function SearchBar({ onBackClick }) {
  return (
    <div className="flex items-center bg-gray-800 p-2 rounded">
      <FaArrowLeft onClick={onBackClick} className="cursor-pointer mr-2" />
      <input
        type="text"
        className="flex-grow bg-gray-700 text-white p-2 rounded outline-none"
        placeholder="Search..."
      />
    </div>
  );
}

export default SearchBar;
