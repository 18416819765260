import React from 'react';

const discoverItems = [
  // Ajouter ici la liste des éléments à découvrir
  { title: 'Le Canap', description: 'La terrible vérité sur David Guetta', image: 'url1' },
  { title: 'Betclic', description: 'Prêt pour la reprise ?', image: 'url2' },
  // etc.
];

function DiscoverGrid() {
  return (
    <div className="grid grid-cols-2 gap-4 p-4">
      {discoverItems.map((item, index) => (
        <div key={index} className="relative">
          <img src={item.image} alt={item.title} className="rounded-lg" />
          <div className="absolute bottom-0 left-0 p-2 bg-black bg-opacity-50 w-full text-white">
            <p className="text-sm">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default DiscoverGrid;
