import React, { useState } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import LittleGame from './components/main/LittleGame';
import FollowedAccounts from './components/main/Inescapable';
import DiscoverGrid from './components/main/DiscoverGrid';

function App() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className="bg-gray-100 min-h-screen flex flex-col">
      <Header />

      <div className="w-full md:max-w-md lg:max-w-lg xl:max-w-xl 2xl:max-w-3xl mx-auto flex-grow space-y-4">
        <LittleGame />
        <FollowedAccounts />
        <DiscoverGrid />

        {/* Bouton pour ouvrir le popup */}
        <button 
          onClick={openPopup} 
          className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700"
        >
          Ouvrir le Popup
        </button>
      </div>

      <Footer />

      {/* Popup */}
      {isPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white w-[95%] h-[80%] rounded-lg shadow-lg p-6 relative">
            <button 
              onClick={closePopup} 
              className="absolute top-4 right-4 bg-red-500 text-white py-1 px-3 rounded hover:bg-red-700"
            >
              Fermer
            </button>
            <div className="text-center">
              <h2 className="text-2xl font-bold mb-4">Mon Popup</h2>
              <p>Contenu de votre popup ici.</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
