// src/components/Footer.js
import React from 'react';
import { FaHome, FaCompass, FaPlusCircle, FaInbox, FaUser } from 'react-icons/fa';

function Footer() {
  return (
    <div className="fixed bottom-0 w-full bg-gray-800 text-white p-3 z-10">
      <div className="max-w-3xl mx-auto flex justify-around">
        <div className="flex flex-col items-center">
          <FaHome className="text-xl" />
          <span className="text-xs">Accueil</span>
        </div>
        <div className="flex flex-col items-center">
          <FaCompass className="text-xl" />
          <span className="text-xs">Découvrir</span>
        </div>
        <div className="flex flex-col items-center">
          <FaPlusCircle className="text-xl text-pink-500" />
        </div>
        <div className="flex flex-col items-center">
          <FaInbox className="text-xl" />
          <span className="text-xs">Boîte de réception</span>
        </div>
        <div className="flex flex-col items-center">
          <FaUser className="text-xl" />
          <span className="text-xs">Profil</span>
        </div>
      </div>
    </div>
  );
}

export default Footer;
