// src/components/Header.js
import React, { useState } from 'react';
import SearchBar from './SearchBar';
import { FaSearch, FaPlusCircle, FaComments } from 'react-icons/fa';

function Header() {
  const [searchOpen, setSearchOpen] = useState(false);

  const handleSearchClick = () => {
    setSearchOpen(true);
  };

  const handleBackClick = () => {
    setSearchOpen(false);
  };

  return (
    <header className="bg-gray-900 text-white p-3">
      {searchOpen ? (
        <SearchBar onBackClick={handleBackClick} />
      ) : (
        <div className="flex justify-between items-center">
          <div className="text-lg font-bold">Hello bitch</div>
          <div className="flex items-center space-x-4">
            <FaSearch onClick={handleSearchClick} className="cursor-pointer" />
            <FaPlusCircle className="cursor-pointer" />
            <FaComments className="cursor-pointer" />
          </div>
        </div>
      )}
    </header>
  );
}

export default Header;
