import React from 'react';

const followedAccounts = [
  // Ajouter ici la liste des comptes suivis avec leurs images et descriptions
  { title: 'Cocovoit', description: 'Quand il est un peu trop fan de quelqu\'un', image: 'url1' },
  { title: 'Drift', description: 'J\'ai ramené une cubaine à la maison pdt les vacances', image: 'url2' },
  // etc.
];

function FollowedAccounts() {
  return (
    <div className="overflow-x-auto flex space-x-4 p-4 bg-black">
      {followedAccounts.map((account, index) => (
        <div key={index} className="flex-shrink-0 w-40">
          <img src={account.image} alt={account.title} className="rounded-lg" />
          <p className="text-white text-sm">{account.description}</p>
        </div>
      ))}
    </div>
  );
}

export default FollowedAccounts;
